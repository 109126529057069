import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loginLoading } = useSelector(
    (state) => state.LoginSlicer
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return () => {
        <Navigate to="/" />;
      };
    }
  }, [isAuthenticated]);

  if (loginLoading) {
    return <LoadingOverlay />;
  }

  return children;
};

export default PrivateRoute;

const LoadingOverlay = () => {
  return (
    <div>
      <CircularProgress />
    </div>
  );
};
