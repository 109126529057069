import React from "react";
import { Box } from "@material-ui/core";
import MiniDrawer from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Button from "../../components/buttons";
import SearchBar from "../../components/searchbar";
import IconCard from "../../components/iconCard";
import Array from "../../components/arrays";
import rca from "../../assets/icons/rca.svg";
import { makeStyles } from "@material-ui/core";
import VideoCards from "../../components/videoCard";
import Mails from "../../components/mails";
import { useSelector } from "react-redux";
const Dashboard = () => {
  const classes = useStyles();
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );
  return (
    <Box display="flex" pt={5} className={classes.bgColor}>
      <MiniDrawer />

      <Wrapper>
        {/* <Box className={classes.flexin}>
          <SearchBar />
          <Button variant="inline" endIcon={<AssessmentIcon />}>
            Report
          </Button>
        </Box> */}
        {/* <div className={classes.grid}>
          {Array.map((items) => {
            return (
              <IconCard
                count={items.count}
                text={items.text}
                icon={items.icon}
              />
            );
          })}
        </div> */}
        {allDocLoading && "loading.."}
        {allDocLoadingSucess && (
          <IconCard count={allDoc.length} text="Total Items" icon={rca} />
        )}
        <Box pt={5} pb={3}>
          <VideoCards />
        </Box>

        {/* <Box pt={5} pb={3}>
          <Mails />
        </Box> */}
        {/* <Box pt={5} pb={3}>
          <ManageNft />
        </Box> */}
        {/* <Box pt={5} pb={3}>
          <FeatureArt />
        </Box> */}
      </Wrapper>
    </Box>
  );
};

export default Dashboard;
const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridGap: "10px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  flexin: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  bgColor: {
    backgroundColor: theme.palette.backgroundColor.bgColor,
  },
}));
