import order from "../../assets/sidebaricons/order.svg";
import rca from "../../assets/sidebaricons/rca.svg";

const SideBarArray = [
  {
    text: "videos",
    icon: rca,
  },
];

export default SideBarArray;
